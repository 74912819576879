import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
function GoBackButton() {
  const navigate = useNavigate();
  const renderTooltip = (props) => <Tooltip id="button-tooltip"{...props}>Lista de radios</Tooltip>;
  return (
    <>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <Button variant="outline-primary" size= "lg" onClick={() =>navigate("/")} style={{marginRight:"20px"}}>
          <FontAwesomeIcon icon={faHouse} />
        </Button>
      </OverlayTrigger>
    </>
  );
}
export default GoBackButton;
