import { React, useContext } from "react";
import { useParams } from "react-router-dom";
import DocHead from "../Components/DocHead";
import { useState, useEffect } from "react";
import firebase from "../Config/firebase";
import { collection, getDocs } from "firebase/firestore";
import Loading from "../Components/Loading";
import NotFound from "../Pages/NotFound";
import GoBackButton from "../Components/GoBackButton";
import { useMediaQuery } from "react-responsive";
import RedesSociales from "../Components/RedesSociales";
import DatosRadio from "../Components/DatosRadio";
import Compartir from "../Components/Compartir";
import BotonReproducir from "../Components/BotonReproducir";
import ReproductorContext from "../Context/ReproductorContext";
import ImagenRadio from "../Components/ImagenRadio";
import Favorito from "../Components/Favorito";
function Listen() {
  const context = useContext(ReproductorContext);
  const { freq } = useParams();
  const [noEncontrado, setnoEncontrado] = useState(false);
  const [radio, setradio] = useState({});
  const [error, seterror] = useState(false);
  const [reload, setReload] = useState(true);
  const [esFavorito, setesFavorito] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 600px)",
  });
  var noFrecuencia = true;

  async function request() {
    try {
      const querySnapshot = await getDocs(collection(firebase.db, "radios"));
      if (querySnapshot.docs) {
        var radios = querySnapshot.docs;
        radios.map((rd) => {
          if (rd.data().freq === freq) {
            setradio(rd.data());

            if (context.url === "") {
              context.Reproducir(
                rd.data().url,
                rd.data().nombre,
                rd.data().UrlWeb,
                rd.data().img
              );
            }

            noFrecuencia = false;
          }
          return true;
        });

        setReload(false);
      }
    } catch (e) {
      seterror(true);
      console.log("Error listen", e);
    }
  }
  useEffect(() => {
    if (localStorage.getItem(radio.nombre)) {
      setesFavorito(true);
    }
  },[radio.nombre]);

  const handleFavorito = () => {
    if (localStorage.getItem(radio.nombre)) {
      localStorage.removeItem(radio.nombre);
      setesFavorito(false);
      } else {
      localStorage.setItem(radio.nombre, "--fav--");
      setesFavorito(true);
      }
  };

  useEffect(() => {
    if (reload) {
      //window.scrollTo(0, 0);
      request().then(() => {
        setnoEncontrado(noFrecuencia);
      });
    }
  });
  if (noEncontrado) {
    return (
      <div>
        <NotFound />
      </div>
    );
  } else {
    if (reload) {
      return <Loading />;
    } else {
      if (error) {
        return <div>Error</div>;
      } else {
        return (
          <div>
            {isDesktopOrLaptop ? (
              <div>
                <div
                  style={{
                    display: "flex",

                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      margin: "20px",
                       lineHeight:"25px"
                    }}
                  >
                    <div style={{ display: "inline-flex" }}>
                      <div>
                        <GoBackButton />
                      </div>

                      <div onClick={handleFavorito}>
                        <Favorito fav={esFavorito} nombre={radio.nombre} />
                      </div>
                    </div>
                    <BotonReproducir {...radio} />
                    <br />
                    <div style={{ display: "none" }}>
                      <h1>{radio.nombre}</h1>
                    </div>
                    <a href={radio.urlWeb} rel="noopener noreferrer" target="_blank" style={{ cursor: "pointer" }}  >
                      <ImagenRadio
                        imagen={radio.img}
                        ancho="380px"
                        nombre={radio.nombre}
                      />
                    </a>
                    <br/>
                    <h2 style={{ fontSize: "smaller" }}>{radio.descripcion}</h2>

                    <RedesSociales {...radio} />

                    <DatosRadio {...radio} />

                    <Compartir
                      titulo={"Escuchá " + radio.nombre + " en OnlineRadios App"}
                      freq={radio.freq}
                    />
                  
                  </div>
                </div>
              </div>
            ) : (
              // es movil
              <div>
                <div>
                  {/*<Reproductor
                    nombre={radio.nombre}
                    url={radio.url}
                    img={radio.img}
                    tipo={radio.tipo}
                    urlWeb={radio.urlWeb}
                    descripcion={radio.descripcion}
            />*/}
                </div>
                <div style={{display:"inline-flex", margin:"20px"}}>
                <div>
                <GoBackButton />
                </div>
                <div onClick={handleFavorito}>
                  <Favorito fav={esFavorito} nombre={radio.nombre} />
                </div>
                </div>
                <BotonReproducir {...radio} />

                <div style={{ display: "none" }}>
                  <h1>{radio.nombre}</h1>
                </div>

                <a href={radio.urlWeb} rel="noopener noreferrer" target="_blank" style={{ cursor: "pointer" }}  >
                  <ImagenRadio
                    imagen={radio.img}
                    ancho="280px"
                    nombre={radio.nombre}
                  />
                </a>

                <h2 style={{ fontSize: "smaller" }}>{radio.descripcion}</h2>
                <div style={{ margin: "20px" }}>
                  <RedesSociales {...radio} />

                  <DatosRadio {...radio} />

                  <Compartir
                    titulo={"Escuchá " + radio.nombre + " en OnlineRadios App"}
                    freq={radio.freq}
                  />
                </div>
                <div style={{margin:"20px"}}>

                
                </div>
              </div>
            )}
            <DocHead
              title={
                radio.nombre + " - OnlineRadios App"
              }
              descripcion={radio.nombre + " - " + radio.descripcion}
              icon={radio.img}
            ></DocHead>
          </div>
        );
      }
    }
  }
}
export default Listen;
