import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPhone, faLocationDot} from "@fortawesome/free-solid-svg-icons"
export default function DatosRadio(props) {
  return (
    <div >
      {props.direccion ? (
        <div>
          <br/>
          <a title="Link a dirección" href={"https://maps.google.com/?q="+props.direccion+ " " + props.ciudad} style={{textDecoration:"none"}}><FontAwesomeIcon style={{marginRight:"10"}} icon={faLocationDot} />{props.direccion} - {props.ciudad}</a>
        </div>
      ) : (
        <div>
          <br/>
          <a title="Link a dirección" href={"https://maps.google.com/?q="+props.ciudad} target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}><FontAwesomeIcon style={{marginRight:"10"}} icon={faLocationDot} />{props.ciudad}</a></div>
      )}
      {props.telefono && (
        <div>
         <br/>
         <a title="Link a llamada telefónica" href={"tel:"+props.telefono} target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}><FontAwesomeIcon style={{marginRight:"10"}} icon={faPhone} />Llamar al {props.telefono}</a>
        </div>
       )}
    </div>
  );
}
