import React from "react";
import ImagenRadio from "./ImagenRadio";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faLocationDot } from "@fortawesome/free-solid-svg-icons";

// ArgBsAsAvellanedaFMPasion
function CardRadio(props) {
  const dt = props.datos;
  const img = dt.img;
  const nombre = dt.nombre;
  const freq = dt.freq;
  const ciudad = dt.ciudad;

 
  const handleClick = () => {
    if (window.scrollY)
      sessionStorage.setItem(window.location.pathname, window.scrollY);
  };

 
 
 
 

 
  
  return (
    <div className="App-CardRadio">
      
     
      <Link
        preventScrollReset={true}
        to={"/" + freq + "/"}
        title={nombre}
        style={{
          textDecoration: "none",
          color: "white",
          cursor: "pointer",
        }}
        onClick={handleClick}
        key={freq}
      >
        <div>
          <div style={{ height: "30px" }}>
            <h1 style={{ fontSize: "14px", marginTop: "10px" }}>{nombre}</h1>
          </div>

          <div
            style={{
              height: "180px",
              alignItems: "center",

              display: "flex",
            }}
          >
            <ImagenRadio imagen={img} ancho="166px" key={img} nombre={dt.nombre} />
          </div>

          <label
            style={{
              marginBottom: "10px",
              cursor: "pointer",
              fontSize: "small",
            }}
          >
            <FontAwesomeIcon icon={faLocationDot} style={{ color: "red" }} id = {dt.nombre} /> {ciudad}</label>
        </div>
      </Link>
    </div>
  );
}
export default CardRadio;
