import React from "react";
import { Button } from "react-bootstrap";
export default function NuevaVersion() {

  
    
  
   const handleClick =() => {
  window.location.reload()
   }
    



    return (
        <div>
        
                <div style={{
                    width: "100%",
                    height: "60px",
                    backgroundColor: "#28B463",
                    color: "white",
                    textAlign: "center", 
                    justifyItems:"center"
                }}>
                    <label style={{ margin: "5px" }}>Nueva version disponible</label>
                    <Button size="sm" style={{ margin: "5px" }} variant='primary' onClick={ handleClick}>Actualizar</Button>

                </div>

        </div>
    )

}
