import React from "react";
import ReactPlayer from "react-player/";
import ReproductorContext from "../Context/ReproductorContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause, faCirclePause, faExclamation } from "@fortawesome/free-solid-svg-icons"
function Reproductor() {


  return (
    <div style={{
      "display": "inline-flex",
      "alignItems": "center",
      "justifyContent": "center",
      "textAlign": "center"
    }}>
      <ReproductorContext.Consumer>
        {(context) => (
          <div>
            <ReactPlayer
              url={context.url}
              controls={false}
              height={"100%"}
              width={"100%"}
              autoPlay={true}
              playing={context.play}
              config={{
                file: {
                  forceAudio: true,
                },
              }}
              volume={context.volumen}
              onReady={() => {
                // context.HandleListo(false)

                context.HandleTexto(<FontAwesomeIcon icon={faCirclePause} style={{ "marginRight": "10px" }} />)
                if (context.play) {
                  context.HandleTexto(<FontAwesomeIcon icon={faPause} style={{ "marginRight": "10px" }} />)

                }
                else {
                  context.HandleTexto(<FontAwesomeIcon icon={faPlay} style={{ "marginRight": "10px" }} />)
                }

              }}
              onStart={() => {
                context.HandleListo(true)
                context.HandleTexto(<FontAwesomeIcon icon={faPause} style={{ "marginRight": "10px" }} />)
                if ('mediaSession' in navigator) {

                  navigator.mediaSession.metadata = new MediaMetadata({
                    title: context.nombre,
                    artist: "OnlineRadios App",
                    artwork: [
                      { src: context.img, sizes: '96x96', type: 'image/png' },
                      { src: context.img, sizes: '128x128', type: 'image/png' },
                      { src: context.img, sizes: '192x192', type: 'image/png' },
                      { src: context.img, sizes: '256x256', type: 'image/png' },
                      { src: context.img, sizes: '384x384', type: 'image/png' },
                      { src: context.img, sizes: '512x512', type: 'image/png' },
                    ]
                  });
                }
              }}
              onPlay={() => {
                context.HandleListo(true)
                context.HandleTexto(<FontAwesomeIcon icon={faPause} style={{ "marginRight": "10px" }} />)
                if ('mediaSession' in navigator) {

                  navigator.mediaSession.metadata = new MediaMetadata({
                    title: context.nombre,
                    artist: "OnlineRadios App",
                    artwork: [
                      { src: context.img, sizes: '96x96', type: 'image/png' },
                      { src: context.img, sizes: '128x128', type: 'image/png' },
                      { src: context.img, sizes: '192x192', type: 'image/png' },
                      { src: context.img, sizes: '256x256', type: 'image/png' },
                      { src: context.img, sizes: '384x384', type: 'image/png' },
                      { src: context.img, sizes: '512x512', type: 'image/png' },
                    ]
                  });
                }
              }
              }
              onPause={() => {
               
               
                context.HandleListo(false)
                context.HandleTexto(<FontAwesomeIcon icon={faPlay} style={{ "marginRight": "10px" }} />)
                context.HandlePlay(false)
               
               

              }}
              onBuffer={() => {
                context.HandleListo(false)
                context.HandleTexto(<FontAwesomeIcon icon={faCirclePause} style={{ "marginRight": "10px" }} />)

              }}
              onError={(e) => {
               
                  console.log("onError", e)

                  context.HandleTexto(<FontAwesomeIcon icon={faExclamation} style={{ "marginRight": "10px" }} />)
                  context.HandlePlay(false)
                  context.HandleListo(false)



               


              }}
            ></ReactPlayer>
          </div>
        )}
      </ReproductorContext.Consumer>
    </div>
  );
}
export default Reproductor;
