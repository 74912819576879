import React from "react";
import { useContext } from "react";
import { Button } from "react-bootstrap";
import ReproductorContext from "../Context/ReproductorContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
export default function BotonReproducir(props) {
    const context = useContext(ReproductorContext)
    const HandleReproducir = () => {
        localStorage.setItem("UltimaRadio", JSON.stringify(props))
        context.HandleListo(false)
        context.HandlePlay(true)
        context.Reproducir(props.url, props.nombre, props.UrlWeb, props.img)
        context.HandleListo(false)
        context.HandleTexto("")
    }
    return (
        
        
        <div>
            <ReproductorContext.Consumer>
                {
                    context =>(
                        <div style={{"margin":"20px"}}>
                            {context.nombre !== props.nombre ?
                             ( 
                                 <Button variant="success"  size="lg" onClick={HandleReproducir}><FontAwesomeIcon icon={faPlay} style={{"cursor":"pointer"}}/> {props.nombre}</Button>
                             ):(
                                <Button variant="outline-success"  size="lg" disabled onClick={HandleReproducir}> {props.nombre}</Button>
                             )
                            }
                        </div>
                    )
                }
            </ReproductorContext.Consumer>
           
        </div>
    )
}