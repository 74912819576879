import { useState, useEffect, useContext } from "react";
import React from "react";
import CardRadio from "../Components/CardRadio";
import DocHead from "../Components/DocHead";
import firebase from "../Config/firebase";
import { collection, getDocs } from "firebase/firestore";
//import { signInWithEmailAndPassword } from "firebase/auth";
import Loading from "../Components/Loading";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRadio } from "@fortawesome/free-solid-svg-icons";
import ReproductorContext from "../Context/ReproductorContext";
import RadiosFavoritas from "../Components/RadiosFavoritas";
import MercadoPagoButton from "../Components/mercadoPagoButton/index"
function Home() {
  var radio = {
    url: "https://27283.live.streamtheworld.com/AM790_56AAC.aac?dist=radiomitre_webplayer&autoplay=0&tdsdk=js-2.9&swm=true&lat=-31.6&long=-60.7&pname=TDSdk&pversion=2.9&banners=none&burst-time=15&sbmid=d62ef35e-99f6-475f-8146-f2f104e9375f",
    nombre: "Radio Mitre",
    UrlWeb: "https://radiomitre.cienradios.com/",
    img: "https://firebasestorage.googleapis.com/v0/b/radiossantafe.appspot.com/o/public%2Fimages%2Fmitre_200x200.webp?alt=media&token=1128febe-631c-4933-8311-02f59cde7831",
  };

  const context = useContext(ReproductorContext);
  const [radios, setradios] = useState([]);
  const [reload, setReload] = useState(true);
  const [loading, setloading] = useState(true);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 600px)",
  });

  if (
    localStorage.getItem("UltimaRadio") === undefined ||
    localStorage.getItem("UltimaRadio") === null
  ) {
    localStorage.setItem("UltimaRadio", JSON.stringify(radio));
  } else {
    radio = JSON.parse(localStorage.getItem("UltimaRadio"));
  }




  async function request() {
    try {
      const querySnapshot = await getDocs(collection(firebase.db, "radios"));
      if (querySnapshot.docs) {
        setradios(querySnapshot.docs);
        setReload(false);
        noCargando();
      }
    } catch (e) {
      console.log("Error home", e);
    }
  }

  function noCargando() {
    setloading(false);
  }
  useEffect(() => {
    if (reload) {
      // signIn()

      context.Reproducir(radio.url, radio.nombre, radio.UrlWeb, radio.img);
     
      request();
    
    }
  });

  useEffect(() => {
    const scrolledY = sessionStorage.getItem(window.location.pathname ?? 0);

    window.scroll(0, scrolledY);
  });


 
  

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  } else {
    return (
      <div className="App-home">
        <RadiosFavoritas />
       
        {/*
        <div>
          <h2 className="App-ubicacion">
            <FontAwesomeIcon
              icon={faLocationDot}
              style={{ marginRight: "10px" }}
            ></FontAwesomeIcon>
            Santa Fe - La Capital
          </h2>
          </div> */}

        {isDesktopOrLaptop ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {radios.map(
              (radio) =>
                radio.data().activo && (
                  <CardRadio datos={radio.data()} key={radio.data().id} />
                )
            )}
          </div>
        ) : (
          <div>
            {radios.map(
              (radio) =>
                radio.data().activo && (
                  <CardRadio datos={radio.data()} key={radio.id} />
                )
            )}
          </div>
        )}

        
          <h1
            style={{
              fontSize: "18px",
              margin: "10px",
              marginTop:"30px"
            }}
          >
            <FontAwesomeIcon icon={faRadio} style={{ marginRight: "10px" }} />
            OnlineRadios App, escuchá radio gratis, en vivo, online las 24hs de Argentina.
          </h1>
       <MercadoPagoButton/>
       <br/>
       <a style={{fontSize:"14px"}} href="mailto:contacto@onlineradios.com.ar" target="_blank" rel="noopener noreferrer" title="Contacto">contacto@onlineradios.com.ar</a>
        <DocHead
          title="OnlineRadios App"
          descripcion="OnlinerRadios App - Radios online, gratis de Argentina"
          

        ></DocHead>
      </div>
    );
  }
}

export default Home;
