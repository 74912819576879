import React from "react";
import { SocialIcon } from "react-social-icons";
export default function RedesSociales(props) {
  const colorFondo = "#A6E8FF "
  const colorFrente ="white"
    return (
    <div>
      {props.whatsapp && (
        <SocialIcon
          target="_blank"
          rel="noopener noreferrer"
          url={props.whatsapp}
          style={{ margin: "20px" }}
          //bgColor={colorFondo}
          fgColor={colorFrente}
          title="WhatsApp"
        />
      )}

      {props.facebook && (
        <SocialIcon
          target="_blank"
          rel="noopener noreferrer"
          url={props.facebook}
          style={{ margin: "20px" }}
          //bgColor={colorFondo}
          fgColor={colorFrente}
          title="Facebook"
        />
      )}

      {props.instagram && (
        <SocialIcon
          target="_blank"
          rel="noopener noreferrer"
          url={props.instagram}
          style={{ margin: "20px" }}
          //bgColor={colorFondo}
          fgColor={colorFrente}
          title="Instagram"
        />
      )}

      {props.twitter && (
        <SocialIcon
          target="_blank"
          rel="noopener noreferrer"
          url={props.twitter}
          style={{ margin: "20px" }}
          bgColor={colorFondo}
          fgColor={colorFrente}
          title="Twitter"
        />
      )}
      {props.youtube && (
        <SocialIcon
          target="_blank"
          rel="noopener noreferrer"
          url={props.youtube}
          style={{ margin: "20px" }}
          //bgColor={colorFondo}
          fgColor={colorFrente}
          title="YouTube"
        />
      )}

      {props.email && (
        <SocialIcon
          target="_blank"
          rel="noopener noreferrer"
          url={props.email}
          style={{ margin: "20px" }}
          //bgColor={colorFondo}
          fgColor={colorFrente}
          title="Email"
        />
      )}
    
    </div>
  );
}
