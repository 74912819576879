// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import "firebase/compat/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC-bC2QO_Ff3oyXBVPa_4KBPLsZEwo4ots",
  authDomain: "radiossantafe.firebaseapp.com",
  projectId: "radiossantafe",
  storageBucket: "radiossantafe.appspot.com",
  messagingSenderId: "905094139187",
  appId: "1:905094139187:web:a0b48f7779e9ab8b928246"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.db = firebase.firestore()
firebase.auth= firebase.auth()
export default firebase