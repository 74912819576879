import React, { useState } from "react";
import ReproductorContext from "./ReproductorContext";

function AuthProvider(props) {
  const [play, setplay] = useState(true);
  const [url, seturl] = useState("");
  const [nombre, setnombre] = useState("");
  const [urlWeb, seturlWeb] = useState("");
  const [img, setimg] = useState("");
  const [texto, settexto] = useState();
  const [listo, setlisto] = useState(false);
  const [volumen, setVolumen] = useState(1);

  const Reproducir = (Url, Nombre, UrlWeb, Img) => {
    seturl(Url);
    setnombre(Nombre);
    setimg(Img);
    seturlWeb(UrlWeb);
  };

  const handleVolumen = (vol) => {
    setVolumen(vol);
  };
  const HandleTexto = (txt) => {
    settexto(txt);
  };
  const HandlePlay = (estado) => {
    setplay(estado);
  };

  const HandleListo = (estado) => {
    setlisto(estado);
  };

  return (
    <ReproductorContext.Provider
      value={{
        url,
        nombre,
        urlWeb,
        img,
        Reproducir,
        HandlePlay,
        play,
        texto,
        HandleTexto,
        listo,
        HandleListo,
        handleVolumen,
        volumen,
      }}
    >
      {props.children}
    </ReproductorContext.Provider>
  );
}
export default AuthProvider;
