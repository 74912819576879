import styles from "./index.module.css"
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
export default function MercadoPagoButton() {
   
    
    return (
        <div style={styles} className="main">

            <br></br>
            <label className={styles.gracias}>Al apoyar monetariamente, colaborás en la mantención de la aplicación y asi poder seguir brindándote una experiencia de calidad.</label>
            <br />
            <br />
            <Link to={"https://mpago.la/2WK6SQ5"}><Button variant="primary">Colaboración</Button></Link>



        </div>
    )
}