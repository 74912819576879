import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import NuevaVersion from "./Components/nuevaVersion/index"
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


const configuration = {

  onUpdate: (registration) => {

    if (registration && registration.waiting) {
     
      ReactDOM.render(

        <React.StrictMode>
         
            <NuevaVersion />
            <App />
          
        </React.StrictMode>
      )
    
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
       // window.location.reload();
    
     
    } 
      
    
    /* if (window.confirm('Nueva versión disponible, hay actualizar?')) {
      
       

     }*/
  }
}


serviceWorkerRegistration.register(configuration);




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
