import React from "react";
import DocHead from "../Components/DocHead";
import GoBackButton from "../Components/GoBackButton"
import Logo from "../Images/OrBanner.png"
function Gracias() {
  return (
    <div>
      <DocHead title="OnlineRadios App"></DocHead>
      <div>
       
        <h1>Gracias!!</h1>
        
        <br />
        <label>Por colaboración podemos mantener la aplicación funcionando</label>
        <div>
          <br />
          <br />
          <img
            alt="OnlineRadios App - Escuchá radios online, en vivo y gratis las 24hs"
            src={Logo}
            style={{ height: "30px", verticalAlign: "top", marginBottom: "10px" }}
          ></img>
        </div>
        <br></br>

        <br />
        <GoBackButton />
        <br />
        <br />
      </div>
    </div>
  );
}
export default Gracias;
