import React from "react";
import { SocialIcon } from "react-social-icons";
function Compartir(props) {
  return (
    <div>
      <label style={{ "fontSize": "small" }}>Compartir radio</label>
      <SocialIcon
        target="_blank"
        rel="noopener noreferrer"
        url={"https://api.whatsapp.com/send?text=https://onlineradios.com.ar/" + props.freq + "/ - " + props.titulo + " - "}
        style={{ margin: "20px", width: "30px", height: "30px" }}
        bgColor="white"
        fgColor="black"
        title="Compartir"
      />
    </div>
  );
}
export default Compartir;
